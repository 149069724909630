/* eslint-disable import/no-cycle */

import { message, notification } from 'antd';
import _ from 'lodash';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import requestError from '@/helpers/requestError';
import requestSuccess from '@/helpers/requestSuccess';
import mockStores from '@/mocks/stores.json';
import mockUserFormData from '@/mocks/userFormData.json';
import mockUsers from '@/mocks/users.json';
import api from '@/services/api';

import Actions, { Types } from './reducer';

export function* syncUsersSagas(action) {
  // Mock Users
  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.syncUsersSuccess(mockUsers.data));
  }

  try {
    const { data } = yield call(api.get, '/users');

    yield put(Actions.syncUsersSuccess(data));
  } catch (error) {
    requestError(error);
    yield put(Actions.syncUsersError());
  }
}

export function* syncStoresSagas(action) {
  // Mock Stores

  if (process.env.REACT_APP_MOCK === 'true') {
    return yield put(Actions.syncStoresSuccess(mockStores.stores, mockStores.ids));
  }

  let page = 1;
  let stores = [];
  let ids = [];
  let hasMoreData = true;

  try {
    do {
      const { data } = yield call(api.get, 'users/locals', { params: { page } });
      if (data.stores.length > 0) {
        stores = stores.concat(data.stores);
        ids = ids.concat(data.ids);
        page += 1;
      } else {
        hasMoreData = false;
      }
    } while (hasMoreData);
  } catch (error) {
    requestError(error);
    return yield put(Actions.syncStoresError());
  }

  return yield put(Actions.syncStoresSuccess(stores, ids));
}

export function* resetLogin({ resetUserId }) {
  try {
    yield call(api.patch, `/users/${resetUserId}`, {
      uuid: null,
      uid: null,
    });

    requestSuccess('Usuário resetado com sucesso!');
  } catch (error) {
    requestError();
  }
}

export function* filterStores(action) {
  const data = yield select((state) => state.UsersReducer);
  const stores = Array.from(Array(data.stores.length).keys());

  if (!data.searchStores && data.storesType === 'all') {
    return yield put(Actions.setIndexedStores(stores));
  }

  const indexedStores = [];

  stores.forEach((storeIndex) => {
    let validate = false;

    const store = data.stores[storeIndex];

    Object.entries(store).forEach((keys) => {
      if (
        String(store[keys[0]])
          .toLowerCase()
          .includes(data.searchStores.toLowerCase())
      )
        validate = true;
    });

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        let key;
        let value;
        Object.entries(values).forEach((keyValue) => {
          [key, value] = keyValue;
        });

        if (data.filterKeys[key].group === 'general' && !value.includes(store[key]))
          validate = false;
        else if (data.filterKeys[key].group === 'general_array') {
          const checkValues = store[key].map((item) => !values.includes(item));
          if (checkValues.every(Boolean)) validate = false;
        }
      });
    }

    if (data.storesType === 'selected' && !data.selectedStoreCodes.includes(store.code)) {
      validate = false;
    }

    if (validate) indexedStores.push(storeIndex);
  });

  yield put(Actions.setIndexedStores(indexedStores));
}

export function* filterUsers(action) {
  const data = yield select((state) => state.UsersReducer);
  const users = Array.from(Array(data.users.length).keys());

  if (!data.searchUsers && _.isEmpty(data.filterValues)) {
    return yield put(Actions.setIndexedUsers(users));
  }

  const indexedUsers = [];

  users.forEach((userIndex) => {
    let validate = false;

    const user = data.users[userIndex];

    Object.entries(user).forEach((keys) => {
      if (
        String(user[keys[0]])
          .toLowerCase()
          .includes(data.searchUsers.toLowerCase())
      )
        validate = true;
    });

    if (!_.isEmpty(data.filterValues)) {
      Object.entries(data.filterValues).forEach(([filter, values]) => {
        let key;
        let value;
        Object.entries(values).forEach((keyValue) => {
          [key, value] = keyValue;
        });

        if (data.filterKeys[key].group === 'general' && !value.includes(user[key]))
          validate = false;
        else if (data.filterKeys[key].group === 'general_array') {
          const checkValues = user[key].map((item) => !values.includes(item));
          if (checkValues.every(Boolean)) validate = false;
        }
      });
    }

    if (validate) indexedUsers.push(userIndex);
  });

  yield put(Actions.setIndexedUsers(indexedUsers));
}

export function* syncUserSagas(action) {
  const { userId } = action;

  // Mock Users
  if (process.env.REACT_APP_MOCK === 'true') {
    let userForm;
    const searchUserMock = (userMock) => {
      if (userMock.id === userId) userForm = userMock;
    };
    mockUserFormData.data.forEach(searchUserMock);
    yield delay(3000);

    yield put(Actions.setUserStoreCodes(userForm.local_ids));
    return yield put(Actions.syncCurrentUserSucess(userForm));
  }

  try {
    const { data, status } = yield call(api.get, `/users/${userId}`);

    if (status === 200) {
      yield put(Actions.setUserStoreCodes(data.local_ids));
      yield put(Actions.syncCurrentUserSucess(data));
    } else {
      yield put(Actions.syncCurrentUserError());
    }
  } catch (error) {
    requestError(error);
    yield put(Actions.syncCurrentUserError());
  }
}

export function* editUser({ currentUser }) {
  const { id, paramsEdit } = currentUser;

  // Mock Send Form
  if (process.env.REACT_APP_MOCK === 'true') {
    message.success('Informações do usuário alteradas com sucesso!');
    return yield put(Actions.editCurrentUserSuccess());
  }

  try {
    yield call(api.patch, `/users/${id}`, {
      ...paramsEdit,
    });

    yield put(Actions.editCurrentUserSuccess());
    yield put(Actions.syncUsers());
    // yield put(Actions.clearData());

    message.success('Informações do usuário editado com sucesso!');
  } catch (error) {
    message.error('Erro na alteração das informações do usuário!');
    yield requestError(error, `O campo ${error.response.data} deve ser único`);
    yield put(Actions.editCurrentUserError());
  }
}

export function* editHierarchyUser({ userCode }) {
  const selectedStoreCodes = yield select((state) => state.UsersReducer.selectedStoreCodes);

  if (process.env.REACT_APP_MOCK === 'true') {
    message.success('Informações da hierarquia do usuário alteradas com sucesso!');
    return yield put(Actions.editCurrentUserSuccess());
  }

  try {
    yield call(api.patch, 'users/hierarchy', {
      local_codes: selectedStoreCodes,
      user_code: userCode,
    });

    yield put(Actions.editCurrentUserSuccess());
    yield put(Actions.syncUsers());

    message.success('Informações da hierarquia do usuário editada com sucesso!');
  } catch (error) {
    message.error('Erro na alteração das informações da hierarquia do usuário!');
    yield put(Actions.editCurrentUserError());
  }
}

export function* onlyFileImport({ fileUrl, importerType, onSuccess, onFailure}) {
  try {
    const {data} = yield call(api.post, `/importers`, {
      source_type: importerType,
      file_url: fileUrl,
    });

    yield put(Actions.onlyFileImportSuccess());
    onSuccess(data.spreadsheet);
    notification.success({
      message: 'Arquivo enviado com sucesso!',
      description: 'Aguarde enquanto o arquivo está sendo processado.',
      placement: 'topRight'
    });
  } catch (error) {
    onFailure({closeImporter: false});
    const customErrorMessage = error?.response?.data?.error || null;
    notification.error({
      message: 'Houve um problema!',
      description: customErrorMessage || 'Verifique seu arquivo e tente novamente.',
      placement: 'topRight'
    });
    yield put(Actions.onlyFileImportError());
  }
}

export function* libraryImport({ values, onSuccess, onFailure }) {
  try {
    const {data} = yield call(api.post, `/importers`, {
      source_type: 'library_create',
      library_params: { ...values },
    });

    yield put(Actions.libraryImportSuccess());
    notification.success({
      message: 'Sucesso!',
      description: 'Sua biblioteca está sendo criada e sendo enviada aos usuários, por favor, aguarde.',
      placement: 'topRight',
    });
    onSuccess(data.spreadsheet);
  } catch (error) {
    onFailure({closeImporter: false});
    notification.error({
      message: 'Houve um problema!',
      description: 'Houveram alguns problemas na criação da biblioteca. Por favor, tente novamente em alguns instantes.',
      placement: 'topRight',
    });
    yield put(Actions.libraryImportError());
  }
}

export function* syncRoles(action) {
  try {
    const { data } = yield call(api.get, '/importers/roles');

    yield put(Actions.syncRolesSuccess(data));
  } catch (error) {
    yield put(Actions.syncRolesError());
  }
}

export default function*() {
  yield all([
    takeLatest(Types.SYNC_USERS, syncUsersSagas),
    takeLatest(Types.SET_SEARCH_USERS, filterUsers),
    takeLatest(Types.SET_FILTER_VALUES, filterUsers),
    takeLatest(Types.SYNC_CURRENT_USER, syncUserSagas),
    takeLatest(Types.EDIT_CURRENT_USER, editUser),
    takeLatest(Types.EDIT_CURRENT_USER, editHierarchyUser),
    takeLatest(Types.SYNC_USERS_STORES, syncStoresSagas),
    takeLatest(Types.SET_SEARCH_STORES, filterStores),
    takeLatest(Types.SET_STORES_TYPE, filterStores),
    takeLatest(Types.SET_SELECTED_STORE_CODE, filterStores),
    takeLatest(Types.DESELECT_ALL_USERS_STORES, filterStores),
    takeLatest(Types.RESET_USER_LOGIN, resetLogin),
    takeLatest(Types.ONLY_FILE_IMPORT, onlyFileImport),
    takeLatest(Types.SYNC_ROLES, syncRoles),
    takeLatest(Types.LIBRARY_IMPORT, libraryImport),
  ]);
}
