import React, { Suspense, memo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Router, Redirect } from 'react-router-dom';

import { has, isEmpty } from 'lodash';

import { isLoggedIn, getUserData, getRole } from '@/auth/redux/reducer';
import Loader from '@/components/Loader';
import { BlankLayout, AppLayout } from '@/layouts';
import ErrorBoundary from '@/routes/ErrorBoundary';

import { rootRoute, authRoutes, pageRoutes, flattenRoutes } from './index';

const Page404 = React.lazy(() => import('@/pages/404'));

const Routes = memo((props) => {
  const userData = useSelector(getUserData);
  const role = useSelector((state) => getRole(state));
  const LoggedIn = useSelector((state) => isLoggedIn(state));
  const Layout = LoggedIn ? AppLayout : BlankLayout;
  let filteredRoutes = pageRoutes;
  const adminSamsungRoutes = ['visits', 'gallery', 'reports', 'visits_form', 'visits_daily'];
  let customRootRoute = rootRoute

  const hasUserData = !isEmpty(userData);

  if (hasUserData && !has(userData?.permissions, 'can_manage_users')) {
    filteredRoutes = filteredRoutes.filter((router) => router.label !== 'users');
  }
  if (hasUserData && has(userData?.permissions, 'admin_samsung')) {
    filteredRoutes = pageRoutes.filter((router) => adminSamsungRoutes.includes(router.label));
  }
  if (hasUserData && !has(userData?.permissions, 'can_justification_web')) {
    filteredRoutes = filteredRoutes.filter((router) => router.label !== 'justifications');
  }

  const rolesHiddenSchedules = ['supervisor', 'trader', 'exporter manager'];

  if (rolesHiddenSchedules.includes(role)) {
    filteredRoutes = filteredRoutes.filter((router) => router.label !== 'schedules');
  }

  if (hasUserData && has(userData?.permissions, 'only_export')) {
    customRootRoute = {
      ...rootRoute,
      component: () => <Redirect to="/reports" />,
    };
    filteredRoutes = filteredRoutes.filter((router) =>
      ['reports', 'visits_form'].includes(router.label)
    );
  }

  const allRoutes = [customRootRoute, authRoutes, ...filteredRoutes];
  const routes = flattenRoutes(allRoutes);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Layout {...props}>
            <Switch>
              {routes.map(
                (route) =>
                  !route.children && (
                    <route.route
                      key={route.key}
                      path={route.path}
                      roles={route.roles}
                      exact={route.exact}
                      restricted={route.restricted}
                      component={route.component}
                    />
                  )
              )}
              <Route render={(data) => <Page404 {...data} />} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
});

export default Routes;
